<template>
  <MasterDetail
    formTitle="Cadastro de Projetos Externos"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="nome_projeto"
    :contextOptions="options"
    :hasYearFilter="hasYearFilter"
    @onOpenFormDialog="getInstituicoes"
  ></MasterDetail>
</template>

<script>
import moment from 'moment';
import MasterDetail from "@/components/master-detail.vue";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    client: function () {
      return this.getClient();
    },
    hasYearFilter: function () {
      return { max: moment().add(1, 'year').get('year') };
    },
    resourceUrl: function () {
      return `/v1/faturamento/projext/projeto/${this.client.clientId}`;
    },
    resourceInstituicoes: function () {
      return this.apiResource(`/v1/faturamento/projext/instituicao/${this.client.clientId}`);
    },
    options: function () {
      const itens = [
        {
          name: "Ver Itens do Projeto",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccess("Faturamento.resource.geral"),
          cb: (row) => {
            this.$router.push({ name: "projeto-externo-detalhes", params: { pId: row.id }});
          },
        }, 
        {
          name: "Controle de Gastos",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccess("Faturamento.resource.geral"),
          cb: (e) => {
            const projetoExternoId = e.id;
            const url = this.$router.resolve({ name: "controle-gastos", params: { projetoExternoId } }).href;
            window.open(url, "_blank");
          },
        },
      ];

      return itens.filter(({ show }) => show);
    }
  },
  data: function () {
    return {
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "instituicao",
          name: "Instituição",
          nowrap: true,
          truncate: true,
          width: '350px',
          hideInform: true,
        },
        {
          key: "regiao",
          name: "Região",
          nowrap: true,
          hideInform: true,
        },
        {
          key: "instituicaoId",
          name: "Instituição",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "instituicoes", key: "id", name: "nome" },
          hideInTable: true,
        },
        {
          key: "nome_projeto",
          name: "Nome Projeto",
          type: this.$fieldTypes.TEXT,
          nowrap: true,
          truncate: true,
          width: '350px',
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "data_ini",
          name: "Data Início",
          align: -1,
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "data_fim",
          name: "Data Fim",
          align: -1,
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "ano_base",
          name: "Ano Base",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 4,
          hideInform: true,
        },
        {
          key: "valorAntecipado_AnoAnterior",
          name: "Antecipado Ano Anterior",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
          hideInform: true,
        },
        {
          key: "valorRepassado_AnoBase",
          name: "Repassado Ano Base",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
          hideInform: true,
        },
        {
          key: "gasto_AnoBase",
          name: "Gasto Ano Base",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
          hideInform: true,
        },
        {
          key: "Antecipado_AnoSeguinte",
          name: "Antecipado Ano Seguinte",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
          hideInform: true,
        },
        {
          key: "observacao",
          name: "Observações",
          type: this.$fieldTypes.TEXTAREA,
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        instituicoes: [],
      },
    };
  },
  methods: {
    getInstituicoes: function () {
      if (!this.opts.instituicoes.length) {
        this.resourceInstituicoes.get().then((response) => {
          this.opts.instituicoes = response;
        });
      }
    },
  },
};
</script>
